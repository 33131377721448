import { browserHistory } from 'react-router'
import { createStore } from 'redux'

import { base_url } from 'src/_util'

import { useSelector, useDispatch } from 'react-redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import { CToaster, CToast, CToastHeader, CToastBody } from '@coreui/react-pro'

import { ary_of_obj_to_obj, decode_token, is_authed } from 'src/_util'

import codes from 'src/codes'

const { pinned_project_ids = '[]' } = localStorage

const initialState = {
  sidebarShow: true,
  asideShow: false,
  aside_form: {
    title: '',
    inputs: {},
  },
  modals_show_std: true,
  theme: 'light',
  is_loading: false,

  context_brand: JSON.parse(localStorage.context_brand || '{}'),
  pinned_project_ids: JSON.parse(pinned_project_ids) || [],

  trigger_reload: new Date().getTime(),

  alert: {},
  accepted_tos: false,

  authed_user: {},
  authed_brands: [],
  brands: [],
  campaigns: [],
  dids: [],
  reports: [],
  projects: [],
  toasts: [],
  users: [],
  convos: [],
  rapid_reply_convos: [],
  rapid_reply_sent: [],
  reserved_convos: [],
  site_wide_alerts: [],

  pre_responses: [],

  files: [
    {
      label: 'Point Blank',
      options: [
        { label: 'sample file name', value: 'file1' },
        { label: 'Only Dems', value: 'file2' },
        { label: 'All Reps', value: 'file3' },
      ],
    },
    {
      label: '[T1 Brand]',
      options: [
        { label: 'Another CSV', value: 'file4' },
        { label: '12/12/12 Resp', value: 'file5' },
        { label: 'All Reps', value: 'file6' },
      ],
    },
  ],
  test_phone_numbers: [],
}

const changeState = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case 'set':
      return { ...state, ...payload }
    case 'CREATE_TOAST':
      var { toasts: existing_toasts = [] } = state
      var { bg_color, text, title, code = '' } = payload

      const { msg, category, title: title_override } = codes[code] || {}

      switch (category) {
        case 'loading':
          title = title_override
          text = msg

          // Skip display of loading toasts
          return {
            ...state,
            toasts: [...existing_toasts],
          }
        case 'success':
          bg_color = 'success'
          title = title_override
          text = msg
          break
        case 'info':
          bg_color = 'info'
          title = title_override
          text = msg
          break
        case 'warning':
          bg_color = 'warning'
          title = `[ERROR ${code}]: `
          text = msg
          break
        case 'error':
          bg_color = 'danger'
          title = `[ERROR ${code}]: `
          text = msg
          break
        default:
      }

      return {
        ...state,
        toasts: [
          ...existing_toasts,
          {
            bg_color,
            title,
            text,
            timestamp: new Date().getTime(),
          },
        ],
      }

    case 'CLEAR_TOASTS':
      return { ...state, toasts: [] }

    case 'SET_THEME':
      var { theme } = payload
      return { ...state, theme }
    case 'SET_IS_LOADING_ACTIVE':
      return { ...state, is_loading: true }
    case 'SET_IS_LOADING_INACTIVE':
      return { ...state, is_loading: false }

    case 'SET_BRAND_USERS':
      var { users } = payload
      return { ...state, users }
    case 'SET_PROJECT':
      var { project, project_id } = payload

      return {
        ...state,
        projects: [...state.projects.filter((p) => p.project_id != project_id), project],
      }
    case 'SET_PRE_RESPONSES':
      var { pre_responses } = payload

      return {
        ...state,
        pre_responses: pre_responses,
      }
    case 'SET_RAPID_REPLIES':
      var { convos } = payload

      return {
        ...state,
        rapid_reply_convos: convos.filter((c) => !state.rapid_reply_sent.includes(c.e164)),
      }
    case 'ADD_RAPID_SENT':
      var { e164 } = payload

      return {
        ...state,
        rapid_reply_sent: [...state.rapid_reply_sent, e164],
      }
    case 'SET_PROJECTS':
      var { projects } = payload
      return { ...state, projects }
    case 'SET_REPORTS':
      var { reports } = payload
      return { ...state, reports }
    case 'SET_USER_BRANDS':
      var { brands, user, brand } = payload

      return {
        ...state,
        authed_brands: brands,
        authed_user: user,
        context_brand: brand,
      }

    case 'DESTROY_USER':
      var { user } = payload
      var { user_id } = user
      return {
        ...state,

        users: [...state.users.filter((u) => u.user_id != user_id)],
      }
    case 'SET_USER':
      var { user } = payload
      var { user_id } = user

      return {
        ...state,

        users: [...state.users.filter((u) => u.user_id != user_id), user],
      }

    case 'LOAD_RESERVED_CONVOS':
      var { convos, id_to_ignore } = payload

      console.log('==1== LOAD_RESERVED_CONVOS', convos)
      console.log('==2== state.reserved_convos', state.reserved_convos)

      // existing convo ids
      const existing_convo_ids = state.reserved_convos.map((c) => c.convo_id)

      // new convos that are not already in the state
      const new_convos = convos.filter((c) => !existing_convo_ids.includes(c.convo_id))

      // filter out the convo with the id_to_ignore
      const filtered_convos = new_convos.filter((c) => c.convo_id != id_to_ignore)

      return {
        ...state,

        reserved_convos: [...state.reserved_convos, ...filtered_convos],
      }
    case 'REMOVE_RESERVED_CONVO':
      var { convo_id } = payload

      return {
        ...state,

        reserved_convos: [...state.reserved_convos.filter((c) => c.convo_id != convo_id)],
      }
    case 'CLEAR_RESERVED_CONVOS':
      // var { convo_id } = payload

      return {
        ...state,

        reserved_convos: [],
      }

    case 'ADD_USER':
      var { user } = payload
      var { user_id } = user

      return {
        ...state,

        users: [...state.users, user],
      }
    case 'LOAD_ALERTS':
      var { site_wide_alerts = [] } = payload
      return {
        ...state,
        site_wide_alerts: site_wide_alerts,
      }
    case 'TRIGGER_LOAD_ALERTS':
      var { dispatch } = payload

      const url = `${base_url()}/alerts`
      const req_headers = new Headers()

      req_headers.append('Content-Type', 'application/json')
      req_headers.append('Authorization', `Bearer ${localStorage.token}`)

      fetch(url, {
        method: 'GET',
        headers: req_headers,
      })
        .then((response) => response.json())
        .then(async (data) => {
          const { alerts } = data

          await dispatch({
            type: 'LOAD_ALERTS',
            site_wide_alerts: alerts,
          })
        })
        .catch((e) => {
          console.log(e)
          dispatch({
            type: 'CREATE_TOAST',

            code: 500.01,
          })
        })

      return state

    case 'ADD_PROJECT':
      var { project } = payload

      return {
        ...state,

        projects: [...state.projects, project],
      }
    case 'UNSET_PROJECT':
      var { project_id } = payload

      return {
        ...state,

        projects: [...state.projects.filter((p) => p.project_id != project_id)],
      }
    case 'UNSET_CAMPAIGN':
      var { campaign_id } = payload

      return {
        ...state,

        campaigns: [...state.campaigns.filter((c) => c.campaign_id != campaign_id)],
      }
    case 'SET_BRANDS':
      var { brands } = payload

      return {
        ...state,

        brands: [...brands],
      }
    case 'SET_PROJECT_CONVOS':
      var { convos } = payload

      return {
        ...state,

        convos: [...convos],
      }
    case 'TOGGLE_CONVO_TAG':
      var { convo_id, tag } = payload

      return {
        ...state,

        convos: [
          ...state.convos.map((convo) => {
            if (convo.convo_id === convo_id)
              convo.tags = convo.tags.includes(tag)
                ? convo.tags.filter((t) => t !== tag)
                : [...convo.tags, tag]

            return { ...convo }
          }),
        ],
      }
    case 'SET_PROJECT_CONVO':
      var { convo } = payload

      return {
        ...state,

        convos: [...state.convos.filter((c) => c.convo_id != convo.convo_id), convo],
      }
    case 'TRIGGER_RELOAD':
      // var { brands } = payload

      return {
        ...state,
        trigger_reload: new Date().getTime(),
      }
    case 'SET_DIDS':
      var { dids } = payload

      return {
        ...state,

        dids: [...dids],
      }
    case 'ADD_DID_TO_CAMPAIGN':
      var { did } = payload

      return {
        ...state,

        dids: [state.dids.concat([did])],
      }
    case 'SET_CAMPAIGNS':
      var { campaigns } = payload

      return {
        ...state,
        campaigns: [...campaigns],
      }
    case 'ADD_CAMPAIGN':
      var { campaign = [] } = payload
      var { campaign_id } = campaign

      return {
        ...state,
        campaigns: [...state.campaigns.filter((c) => c.campaign_id != campaign_id), campaign],
      }
    case 'SET_USER_AUTH':
      var { token } = payload
      localStorage.token = token
      return {
        ...state,
        authed_user: decode_token(token),
      }

    case 'SET_ASIDE_FORM':
      var { inputs, onSubmit } = payload
      var { aside_form } = state

      return {
        ...state,
        aside_form: {
          ...aside_form,
          ...payload,
          onSubmit,
          inputs: ary_of_obj_to_obj(inputs, 'id'),
        },
      }
    case 'ON_CHANGE_ASIDE_FORM_INPUT':
      var { key, value } = payload
      var { aside_form } = state

      return {
        ...state,
        aside_form: {
          ...aside_form,
          inputs: {
            ...aside_form.inputs,
            [key]: {
              ...aside_form.inputs[key],
              value,
            },
          },
        },
      }

    case 'modal.close':
      var { target } = payload
      return {
        ...state,
        modals_show_std: false,
      }
    case 'SET_PINNED':
      const project_ids = payload?.selected || []
      localStorage.setItem('pinned_project_ids', JSON.stringify(project_ids))
      return { ...state, pinned_project_ids: project_ids }
    case 'CLEAR_PINS':
      localStorage.pinned_project_ids = '[]'

      return { ...state, pinned_project_ids: [] }
    case 'SET_TOS_ACCEPTED':
      return { ...state, accepted_tos: true }
    case 'SET_CONTEXT_BRAND':
      var { authed_brands } = state
      var { brand_id } = payload
      if (!brand_id) return state

      var brand = authed_brands.filter((i) => i.brand_id === brand_id).shift()
      localStorage.context_brand = brand ? JSON.stringify(brand) : '{}'
      return { ...state, context_brand: brand }

    case 'SET_CONTEXT_BRAND_INIT':
      var { brand_id } = payload
      if (!brand_id) return state

      return { ...state, context_brand: { brand_id } }
    // if (is_authed(brand?.brand_id, fake_token)) {
    //   localStorage.context_brand = JSON.stringify(context_brand)
    //   return { ...state, context_brand }
    // }
    default:
      return state
  }
}

const store = createStore(changeState, composeWithDevTools())
export default store
